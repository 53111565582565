import React, { useContext, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Row,
  Accordion,
  Card,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap"
import "aos/dist/aos.css"
import AOS from "aos"
import { Link } from "gatsby"
import wedoban from "../images/whatWeDo/banner.png"
import fellows from "../images/whatWeDo/sunshine.png"
import tfa from "../images/teachforall.png"
import cdots1 from "../images/dots/wedot/1.png"
import cdots2 from "../images/dots/wedot/2.png"
import cbdots1 from "../images/dots/wedob/1.png"
import cbdots2 from "../images/dots/wedob/2.png"
import cbdots3 from "../images/dots/wedob/3.png"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const WhatWeDo = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Layout pageName="What we do">
      <SEO title="What We Do" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={wedoban} />
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">The Problem</p>
              <p className="fi-head mb-lg-3 mb-3">India’s ed crisis</p>
              <p className="fi-txt  mb-lg-5 mb-4">
                We are facing a crisis of unprecedented proportions- a
                reflection of our unjust system that dictates the fate of
                children based on their socio-economic circumstances. A system
                that excludes children from education and perpetuates practices
                that shrink their limitless potential. The state of education
                before the pandemic struck was worrying enough and now things
                have just gotten worse.
              </p>
              <p>
                <a
                  href="https://apply.teachforindia.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="butn butn-main butn-sp butn-sm-sp mr-2"
                >
                  Apply for the Fellowship
                </a>
                <Link
                  to="/volunteer"
                  className="butn butn-main butn-sp butn-sm-sp"
                >
                  Volunteer with us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Container>
        <div className="pt-5 mb-lg-5 patch-cont text-center">
          <img
            src={cdots1}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <img
            src={cdots2}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <p className="fi-head py-5">The reality of inequity</p>
        </div>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Hunger Index</p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India ranked 94 among 107 countries in the Global Hunger Index
              (GHI) 2020 and continues to be in the “serious” hunger category.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://timesofindia.indiatimes.com/india/india-ranks-94/107-in-global-hunger-index/articleshow/78727057.cms#:~:text=NEW%20DELHI%3A%20India%20ranked%2094,102%20out%20of%20117%20countries"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Oxfam Inequality Index</p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India fared poorly, ranking 147 out of 157 countries, in terms of
              its commitment to reducing inequality, while Denmark topped the
              list.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://www.dnaindia.com/business/report-india-ranked-147-out-of-157-countries-on-oxfam-index-on-efforts-to-reduce-inequality-2673434#:~:text=India%20fared%20poorly%2C%20ranking%20147,list%2C%20a%20report%20said%20Tuesday"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Water quality index </p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              Globally, India is ranked 120 out of 122 countries in WaterAid’s
              water quality index.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://www.orfonline.org/expert-speak/quality-of-water-in-indias-state-capitals-58308/#:~:text=Globally%2C%20India%20is%20ranked%20120th,in%20WaterAid's%20water%20quality%20index"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Air Quality Index </p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              While India is at the bottom of the list in the environmental
              health category, it ranks 177 out of 180 as far as air quality is
              concerned.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://economictimes.indiatimes.com/news/economy/indicators/india-ranks-177-out-of-180-in-environmental-performance-index/articleshow/62637626.cms?from=mdr"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">UN World Happiness Index </p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India is at 139 out of 156 countries.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://www.google.com/search?q=un+world+happiness+index+india+rank&oq=un+world+&aqs=chrome.2.69i57j0i67i457j0i67j0i433j0i67j0l2j69i60.2979j0j7&sourceid=chrome&ie=UTF-8"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">World Press Freedom Index</p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India ranks at 142 out of 180 countries.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://rsf.org/en/ranking_table"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Environment Quality Index</p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India secured the 168th rank in the 12th edition of the biennial
              Environment Performance Index (EPI Index 2020).
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://www.downtoearth.org.in/news/wildlife-biodiversity/india-must-redouble-sustainability-efforts-environment-performance-index-71603#:~:text=India%20secured%20168%20rank%20in,out%20of%20100)%20in%202018"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
        <Row className="borderBottomSec mx-0 align-center">
          <div className="col-lg-6 px-0 ">
            <p className="ed-head">Future Index </p>
          </div>
          <div className="col-lg-6 px-0 ">
            <p className="fi-txt mb-lg-0">
              India ranks 35th on the worldwide education for the future index.
            </p>
            <p className="text-left text-lg-right mb-lg-0">
              <a
                href="https://www.indiaitaly.com/post/india-jumps-5-ranks-to-be-35th-in-worldwide-education-for-future-index-2019"
                target="_blank"
                rel="noreferrer noopener"
                className="uc-link"
              >
                Read More
              </a>
            </p>
          </div>
        </Row>
      </Container>

      <Container className="pt-5 mt-lg-5">
        <div className="max-75 mx-auto text-center">
          <p className="fi-head py-5">
            The Grey Sunshine of the Education System
          </p>
        </div>
        <Row>
          <div className="col-lg-8 px-3 pr-lg-4">
            <img
              src={fellows}
              alt="Fellows Image"
              className="w-100 round-border"
            />
          </div>
          <div className="col-lg-4 pt-3">
            <p className="pt-2 fel-txt mb-3">
              Over the past decade, India has made significant progress in the
              area of education:
            </p>
            <ul className="fel-ul">
              <li>
                Access to primary and secondary school has improved dramatically
              </li>
              <li>A growing literacy rate</li>
              <li>
                Education as a fundamental right, with the RTE Act of 2009
              </li>
              <li>
                A national dialogue on the need for education, motivated by
                parental demands and global competitive standards
              </li>
              <li>
                Multiple stakeholders- private and public- working for
                sustainable, nationwide change.
              </li>
            </ul>
          </div>
        </Row>
      </Container>
      <Container className="text-center pt-5">
        <p className="ed-title d-none d-lg-block">The Challenge </p>
        <p className="wn-title mt-0 d-block d-lg-none">The Challenge </p>
        <p className="fi-head max-80 max-sm-100 mx-auto d-none d-lg-block">
          Accelerated learning gap
        </p>
        <p className="fi-txt max-70 max-sm-100 px-lg-4 px-0 mx-auto">
          Low-income children have suffered tremendous learning loss in the past
          year. The truth is that today, we are failing the majority of our
          children, and in turn an entire generation that can take our nation
          to greatness. The fact is that teachers alone cannot solve this
          crisis; we also need excellent school principals to support those
          teachers, informed parents to stay engaged with the teaching-learning
          process in schools, visionary bureaucrats and politicians to create an
          environment that enables principals and teachers to thrive, active
          civil society leaders to hold stakeholders accountable, and committed
          corporate leaders to mobilise the necessary resources to support
          school systems.
        </p>
        <div className="max-50 max-sm-100 mx-auto wedoColorCont">
          <img
            src={cbdots1}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
          <img
            src={cbdots2}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
          <img
            src={cbdots3}
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
        </div>
      </Container>

      <Container>
        <div className="text-center pt-5 pt-lg-0 pb-lg-5">
          <p className="ed-title">The Solution</p>
          <p className="fi-head max-75 max-sm-100 mx-auto mb-0">
            What the Indian Education System Urgently needs?
          </p>
        </div>
        <Accordion className="faq-acc">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="0">
                <div>
                  <p className="fi-txt">
                    Access to early childhood care and education
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p className="fi-txt text-left">
                  With a significant percentage (9.7%) of India’s population
                  below 5 years of age, there is a massive need for a policy
                  framework that supports Early Childhood Care and Education
                  (ECCE). However, the reality is far from the vision of
                  integrated ECCE that is needed to give all children a sound
                  foundation for lifelong learning & development. Teach For
                  India Alumni in organizations like Hippocampus, Thermax
                  Foundation, Wunderbar, Samit Shala, Pune Children’s Zone and
                  Pratham are working on different aspects of this issue.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1">
                <div>
                  <p className="fi-txt">
                    Development of teachers and school principals
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p className="fi-txt text-left">
                  Building capability in teachers has been a major challenge
                  with pre-service and in-service teacher training falling short
                  of equipping our teachers with the skills and mindsets
                  required to succeed in a diverse range of classroom and school
                  contexts. In addition, the country faces a shortage of over 9
                  lakh teachers and qualified headteachers who can lead a
                  school. In addressing this multifaceted challenge, Teach For
                  India Alumni are engaged in helping teachers improve their
                  effectiveness. Through organizations like iTeach, Leadership
                  Institute for Teachers, TalentSprint, STIR, Firki and India
                  School Leadership Institute, our Alumni are showing what is
                  possible when we look at teachers as key partners in education
                  reform.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                <div>
                  <p className="fi-txt">Design of curriculum and assessments</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div>
                  <p className="fi-txt text-left">
                    The National Curriculum Framework (NCF) results in
                    curricular policies and practices that, as noted by a SCERT-
                    led review, are “not suitable for children from all sections
                    of society”, “not local specific”, and “not related to
                    day-to-day life” of the students and teachers. Indian
                    curriculum has also been shown to be “overambitious” -
                    content not suited to the pace of children’s learning.
                  </p>
                  <p className="fi-txt text-left">
                    Similarly, this has been the case with the reform movement
                    in the space of assessments with the Continuous and
                    Comprehensive Evaluation (CCE) system. Combine this with the
                    lack of a uniform national large-scale student assessment
                    programme and one encounters a challenging scenario where
                    there is very little objective information on student
                    learning outcomes for decision-making at the school or
                    policy level.
                  </p>
                  <p className="fi-txt text-left">
                    With the journey of curriculum and assessments beginning as
                    soon as one enters the classroom, we see many Fellows
                    continuing to work as Alumni in creating contextualized
                    curriculum, assessments and frameworks, for different modes
                    of delivery, and in training teachers and schools in
                    performing assessment-related tasks, in organisations such
                    as Educational Initiatives, iDiscoveri, Gray Matters,
                    Leadership Boulevard, and Bridge International Academies.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                <div>
                  <p className="fi-txt">Community empowerment</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div>
                  <p className="fi-txt text-left">
                    In a system that is as massive as ours, the mode of fixing
                    accountability from the top creates extreme challenges. In
                    such a scenario, empowering communities at a school-level
                    for governance is critical to the success of the movement
                    towards quality. Through this, the school becomes the
                    reflection of its community, with its local set of
                    priorities, values and needs.
                  </p>
                  <p className="fi-txt text-left">
                    To this effect, Section 21 of the RTE Act (2009) mandates
                    the formation of School Management Committees (SMCs) in all
                    government-run or aided schools. With an eye on equitable
                    decision-making, parents or guardians are supposed to make
                    up 75% of the committee, with proportional representation
                    for disadvantaged groups and 50% representation by women.
                    However, high incidence of illiteracy, lack of awareness of
                    rights, roles and responsibilities, and prevailing power
                    structures often interfere with the proper functioning of
                    SMCs.
                  </p>
                  <p className="fi-txt text-left">
                    Teach For India Fellows as well as Alumni in organizations
                    like Saajha, AfterTaste, Akanksha and Indus Action are
                    solving various adaptive challenges related to community
                    empowerment and harmonizing the power of the collective by
                    rallying and organizing parents and other community members
                    around school transformation.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="4">
                <div>
                  <p className="fi-txt">
                    Integration of technology in education
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p className="fi-txt text-left">
                  With the spread of the digital revolution and increased
                  globalization, education systems across the world are being
                  subjected to a variety of forces that are pushing and pulling
                  at it. COVID-19 brought alive the class difference and
                  deepened the digital divide.
                </p>
                <p className="fi-txt text-left">
                  With content being tailored to individual needs and with the
                  ability to provide instant feedback to both students and
                  teachers, technology has the potential to let each child learn
                  at their own pace. Teach For India Alumni are working
                  tirelessly to integrate technology into India’s classrooms
                  through roles in education-technology organizations like
                  EkStep, Meghshala, Central Square Foundation, Nalanda, and
                  Zaya, to name just a few.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>

      <Container className="py-5">
        <div className="text-center">
          <p className="fi-head">Our Model</p>
          <p className="fi-txt max-60 max-sm-100 mx-auto mb-4">
            In order to build a growing community of leaders, Teach For India
            has developed a two-part theory of change. At Teach For India, we
            believe that at the root of this crisis in education lies a crisis
            of leadership. We exist in order to fix that deficit.{" "}
          </p>
          <iframe
            width="100%"
            id="youtubeplayer"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/mnozKzNsry4"
            title="Teach For India"
            className="d-none d-lg-block"
          ></iframe>
          .
          <iframe
            width="100%"
            id="youtubeplayermob"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/mnozKzNsry4"
            title="Teach For India"
            className="d-block d-lg-none"
          ></iframe>
        </div>
        <div>
          <Accordion className="cpp-acc wedo" defaultActiveKey="10">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <Row className="py-3">
                    <div className="col-lg-5 pr-5 ">
                      <p className="wn-title mb-4">The Fellowship </p>
                      <p>
                        <Link to="/life-as-a-fellow" className="butn butn-main">
                          Know more
                        </Link>
                      </p>
                    </div>
                    <div className="col-lg-7 spp-cont">
                      <p className="fi-txt">
                        In the short-term, through our Fellowship program, we
                        provide an opportunity to India’s brightest and most
                        promising individuals, from the nation’s best
                        universities and workplaces, to serve as full-time
                        teachers to children from low-income communities in some
                        of the nation’s most under-resourced schools.
                      </p>
                      <p className="fi-txt">
                        Through this experience of teaching in classrooms and
                        working with key education stakeholders like students,
                        principals, and parents, our Fellows get exposed to the
                        grassroots realities of India’s education system and
                        begin to cultivate the knowledge, skills, and mindsets
                        necessary to attain positions of leadership in the
                        education system and identify their role in building a
                        larger movement for equity in education.
                      </p>
                      <p className="fi-txt">
                        In the past decade, more than 120,000 people have
                        applied to the Teach For India Fellowship.
                      </p>
                      <p className="fi-txt">
                        Our selectivity ranges from 6.00-8.36% competing with
                        leading universities.
                      </p>
                      <span className="acc-plus-icon">+</span>
                      <span className="acc-minus-icon">-</span>
                    </div>
                  </Row>
                </ContextAwareToggle>
              </Card.Header>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <Row className="py-3">
                    <div className="col-lg-5 pr-5 ">
                      <p className="wn-title mb-4">The Alumni Movement </p>
                      <p>
                        <Link to="/life-as-alumni" className="butn butn-main">
                          Know more
                        </Link>
                      </p>
                    </div>
                    <div className="col-lg-7 spp-cont">
                      <p className="fi-txt">
                        In the long-term, we engage these leaders, our Alumni,
                        and support this growing community to advocate for
                        change. Our Alumni work in diverse roles within the
                        education sector, as teachers, teacher-trainers, school
                        principals, curriculum designers, and education policy
                        researchers, as well as in the ecosystem surrounding and
                        supporting the education sector, as journalists,
                        lawyers, health experts, entrepreneurs, and corporate
                        leaders.
                      </p>
                      <p className="fi-txt">
                        All of them share a purpose- to build a broad people’s
                        movement for educational equity that will accelerate
                        progress towards that day when all children in India
                        have the opportunity to attain an excellent education so
                        that we, as a nation, can find our light and true
                        potential.
                      </p>
                      <span className="acc-plus-icon">+</span>
                      <span className="acc-minus-icon">-</span>
                    </div>
                  </Row>
                </ContextAwareToggle>
              </Card.Header>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <Row className="py-3">
                    <div className="col-lg-5 pr-5 ">
                      <p className="wn-title mb-4">Where we work </p>
                      <p>
                        <Link to="/regions" className="butn butn-main">
                          Know more
                        </Link>
                      </p>
                    </div>
                    <div className="col-lg-7 spp-cont">
                      <p className="fi-txt">
                        Our network spans 8 cities in India. Ahmedabad,
                        Bengaluru, Chennai, New Delhi, Hyderabad, Kolkata,
                        Mumbai and Pune.
                      </p>
                      <p className="fi-txt">
                        Our Alumni and Fellows liaise with multiple stakeholders
                        in rural and urban communities across India in order to
                        create tangible change for India’s children.
                      </p>
                      <p className="fi-txt">
                        Since 2009, we have built the largest pipeline of
                        skilled and driven talent in the Indian educational
                        ecosystem.
                      </p>
                      <span className="acc-plus-icon">+</span>
                      <span className="acc-minus-icon">-</span>
                    </div>
                  </Row>
                </ContextAwareToggle>
              </Card.Header>
            </Card>
          </Accordion>
        </div>
      </Container>
      <Container className="text-center py-5">
        <div className="pt-5 mb-lg-5 patch-cont text-center">
          <img
            src={cdots1}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <img
            src={cdots2}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="color-patcher d-none d-lg-block"
          />
          <p className="ed-title d-none d-lg-block">Our People </p>
          <p className="wn-title mt-0 d-block d-lg-none">Our People </p>
          <p className="fi-head max-60 max-sm-100 mx-auto d-none d-lg-block">
            Our movement is fuelled by collective leadership at all levels
          </p>
        </div>

        <p className="fi-txt max-70 max-sm-100 px-0 px-lg-4 mx-auto">
          We consider our people to be our best assets. The team comes from all
          sectors, fields of expertise, industries and walks of life.
          There&apos;s just one thing that connects them all - they have
          children at the core of everything they do. Meet our leadership team
          that strives relentlessly for an India free of poverty and filled with
          love.
        </p>
        <div className="text-center">
          <a className="butn butn-main" href="/our-people">
            Know more
          </a>
        </div>
      </Container>

      <Container className="text-center pb-5">
        <div className="max-50 max-sm-100 mx-auto wedoColorCont">
          <img
            src={cbdots1}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
          <img
            src={cbdots2}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
          <img
            src={cbdots3}
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            className="w-100 wedoColorPatch"
          />
        </div>
        <p className="ed-title d-none d-lg-block">Our Values</p>
        <p className="wn-title mt-0 d-block d-lg-none">Our Values</p>
        <p className="fi-head max-60 max-sm-100  mx-auto d-none d-lg-block">
          Our work is deeply rooted in the 8Cs
        </p>
        <p className="fi-txt max-70 max-sm-100 px-0 px-lg-4 mx-auto">
          Teach For India believes that through the work we do, we must reflect
          the values we believe in. The 8cs work as guiding principles for our
          mission. They help our people grow and evolve into caring, responsible
          adults in service of children.
        </p>
        <div className="text-center">
          <a
            className="butn butn-main"
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/drive/folders/17qttI9gbxvQ5CcVAbhDJepboZCbd-TVK"
          >
            Know more
          </a>
        </div>
      </Container>
      <Container>
        <div className="text-center">
          <div className="max-45 my-4 my-lg-5 px-lg-5 mx-auto">
            <img src={tfa} className="w-100" />
          </div>
          <p className="fi-txt max-60 max-sm-100 mx-auto mb-3 mb-lg-3">
            Teach For India is a part of the Teach For All Network, a growing
            group of independent organizations that are working to expand
            educational opportunity in their nations. Teach For All is a
            platform for shared learning and deep engagement across countries.
            The 61 countries in the network today share a common vision that one
            day all children will attain an excellent education.
          </p>
          <p>
            <a
              href="https://teachforall.org/"
              rel="noreferrer noopener"
              target="_blank"
              className="butn butn-main"
            >
              Know More
            </a>
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default WhatWeDo
